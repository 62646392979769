<template>
  <div class="clientMain">
    <ClientHeader />
    <div class="body">
      <ClientOrders />
    </div>
    <div class="Footer">
      <ClientFooter />
    </div>
  </div>
</template>
<script>
import ClientHeader from '@/components/food/ClientHeader';
import ClientFooter from '@/components/food/ClientFooter';
import ClientOrders from '@/components/food/ClientOrders';

export default {
  data() {
    return {};
  },
  components: {
    ClientHeader,
    ClientFooter,
    ClientOrders
  }
};
</script>
<style scoped>
.clientMain {
  background-color: rgb(255, 255, 255);
  font-family: RussianRail;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.Footer {
  flex: 0 0 auto;
}
.body {
  border-radius: 10px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3%;
  margin-bottom: 3%;
  background-color: white;
  padding-bottom: 30px;
  flex: 1 0 auto;
}
</style>
