<template>
  <div>
    <div v-if="currClient === null || currClient === undefined">
      <h1>Пожалуйста, авторизуйтесь</h1>
    </div>
    <div v-else>
      <v-breadcrumbs :items="breadItems"></v-breadcrumbs>
      <v-card v-if="allOrdersData != undefined && allOrdersData !== null">
        <v-card-title>Последний заказ</v-card-title>
        <v-card-text> Номер заказа: {{ allOrdersData.orderNum }} </v-card-text>
        <v-card-text> Сумма: {{ allOrdersData.total }} руб. </v-card-text>
        <v-card-text>Статус: {{ getStatus(allOrdersData.statusId) }}</v-card-text>
        <v-card-text> Создано: {{ formatDate(allOrdersData.createdAt) }} </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import ViewHelper from '@/helpers/view.helper';
import moment from 'moment';

export default {
  data() {
    return {
      currClient: JSON.parse(localStorage.getItem('client')),
      breadItems: [
        {
          text: 'Вернуться на главную',
          disabled: false,
          to: '/'
        }
      ]
    };
  },
  computed: {
    clientObj() {
      let obj;
      if (this.currClient !== null && this.currClient !== undefined) {
        obj = JSON.parse(JSON.stringify(this.currClient));
      }
      return obj;
    },
    ...mapGetters('client', ['allOrdersData']),
    ...mapGetters('client', ['clientOrderTimer']),
    ...mapGetters('client', ['allOrdersResult'])
  },
  methods: {
    formatDate: ViewHelper.formatDate,
    getAllClientOrders() {
      if (this.currClient !== null && this.currClient !== undefined) {
        this.getAllOrder(this.clientObj);
      }
    },
    getStatus(order) {
      return ReferenceHelper.getOrderStatusForClient(order);
    },
    ...mapActions('client', ['getAllOrder'])
  },
  mounted() {
    this.getAllClientOrders();
    this.interval = setInterval(() => {
      let isNeedToUpdate = false;
      try {
        if (this.clientOrderTimer === undefined || this.clientOrderTimer === null) {
          isNeedToUpdate = true;
        } else {
          if (this.clientOrderTimer.isBefore(moment().subtract(10, 'm'))) {
            isNeedToUpdate = true;
          }
        }
      } catch (ex) {
        console.log(ex);
        isNeedToUpdate = true;
      }
      if (isNeedToUpdate) {
        this.getAllClientOrders();
      }
    }, 60000);
  },
  beforeDestroy() {
    if (this.interval !== null) {
      clearInterval(this.interval);
    }
  }
};
</script>
